import clsx from 'clsx';
import * as api from 'ego-sdk-js';
import React from 'react';

import 'keen-slider/keen-slider.min.css';
import { KeenSliderPlugin, useKeenSlider } from 'keen-slider/react';
import { ElementContent } from 'react-markdown/lib';

import AngleLeftIcon from './icon/AngleLeftIcon';
import AngleRightIcon from './icon/AngleRightIcon';
import ImageWithViewer from './ImageWithViewer';
import { useKeyPress } from './KeyPressContext';
import { usePrexoNextSlide, usePrexoSlideIndex } from './PrexoSlideContext';

/**
 * ADAPTED FROM CpcViewer's WheelControls.
 *
 * There are three distinct Wheel behaviors that were tested and needed care
 * to be handled in a unified way:
 *
 * 1. Mac Touchpad: Touch gestures have lots of momentum. Long after the user
 *    has let go, the Mac is still generating wheel events at monotonically
 *    decreasing (or equal) deltas.
 * 2. Ubuntu Thinkpad Touchpad: Like the mac's touch gestures, but there is no
 *    momentum at all. Once the user has let go, the events stop. The delta
 *    values are also significantly larger (~2x).
 * 3. Desktop Mouse Wheel: Every increment of the wheel produces an identical
 *    high delta (~200). There is no momentum.
 */
const HorizontalWheelControls: KeenSliderPlugin = slider => {
  let activeTimeout: ReturnType<typeof setTimeout>;
  let touchTimeout: ReturnType<typeof setTimeout>;
  let position: {
    x: number;
    y: number;
  };
  let wheelActive: boolean;

  const deltaXAbsHistory: number[] = [];
  let slowingDownCount = 0;

  /**
   * update() keeps track of the history of wheel event deltas even if our
   * heuristic has decided that the user is no longer actively scrolling
   * (momentum on mac is generating events).
   */
  function update(e: WheelEvent) {
    const deltaXAbs = Math.abs(e.deltaX);
    if (deltaXAbs <= 2) {
      // No need for this level of sensitivity (only on mac).
      return;
    }
    const deltaXAbsAvg = deltaXAbsHistory.reduce((a, b) => a + b, 0) / deltaXAbsHistory.length;
    if (deltaXAbsAvg + 1 >= deltaXAbs) {
      slowingDownCount += 1;
    } else {
      slowingDownCount = 0;
    }
    deltaXAbsHistory.push(deltaXAbs);
    if (deltaXAbsHistory.length > 3) {
      // Long history necessary because mac's have a long inertial period with
      // consistent non-decreasing values.
      deltaXAbsHistory.shift();
    }
  }

  function wheelStart(e: WheelEvent) {
    position = {
      x: e.pageX,
      y: e.pageY,
    };
    slider.container.dispatchEvent(
      new CustomEvent('ksDragStart', {
        detail: {
          x: position.x,
          y: position.y,
        },
      }),
    );
  }

  function wheelEnd(_e: WheelEvent) {
    slider.container.dispatchEvent(
      new CustomEvent('ksDragEnd', {
        detail: {
          x: position.x,
          y: position.y,
        },
      }),
    );
  }

  function eventWheel(e: WheelEvent) {
    if (e.deltaX === 0) {
      // Do not capture vertical scroll.
      return;
    }
    e.preventDefault();
    update(e);

    const alive: boolean = slowingDownCount <= 3;
    if (alive) {
      if (!wheelActive) {
        // If wheel isn't officially active, time to activate it!
        wheelStart(e);
        wheelActive = true;
      }
      position.x -= e.deltaX;
      position.y -= e.deltaY;

      slider.container.dispatchEvent(
        new CustomEvent('ksDrag', {
          detail: {
            x: position.x,
            y: position.y,
          },
        }),
      );
      // If no further events generate an alive signal (in other words, the
      // deltas are monotonically decreasing), the timeout will mark the
      // wheel as inactive even as events continue to be generated.
      clearTimeout(activeTimeout);
      activeTimeout = setTimeout(() => {
        wheelActive = false;
        wheelEnd(e);
      }, 50);
    }
    // Once there are no more events (regardless of whether we think the wheel
    // is active or not), we can safely clear the delta history.
    clearTimeout(touchTimeout);
    touchTimeout = setTimeout(() => {
      deltaXAbsHistory.splice(0, deltaXAbsHistory.length);
    }, 100);
  }

  slider.on('created', () => {
    slider.container.addEventListener('wheel', eventWheel, {
      passive: false,
    });
  });
};

const ResizePlugin: KeenSliderPlugin = slider => {
  const observer = new ResizeObserver(() => slider.update());

  slider.on('created', () => observer.observe(slider.container));
  slider.on('destroyed', () => observer.unobserve(slider.container));
};

const ImageSlider = (props: {
  imgNodes: ElementContent[];
  contentImageSpecs?: api.feed.IContentImageSpec[];
  gutterClassName?: string;
  parentSlideIndex?: number; // Set if in prexo
}) => {
  const prexoNextSlide = usePrexoNextSlide();
  const prexoSlideIndex = usePrexoSlideIndex();
  const imageInfoMap: Map<string, api.feed.IContentImageSpec> = new Map();
  if (props.contentImageSpecs) {
    for (const imageInfo of props.contentImageSpecs) {
      imageInfoMap.set(imageInfo.url, imageInfo);
    }
  }

  const [slideIndex, setSlideIndex] = React.useState(0);
  const [sliderDivRef, sliderRef] = useKeenSlider<HTMLDivElement>(
    {
      detailsChanged: slider => {
        setSlideIndex(slider.track.details.abs);
      },
      drag: true,
      dragSpeed: 0.1,
      initial: 0,
      slides: { perView: 1 },
    },
    [HorizontalWheelControls, ResizePlugin],
  );

  React.useEffect(() => {
    // HACK: Without this, keen uses incorrect layout dimensions to size the
    // image slider.
    if (sliderRef.current) {
      sliderRef.current.update();
    }
  }, [sliderRef.current]);

  useKeyPress(
    'l',
    () => {
      // l: Move to next image.
      if (slideIndex + 1 < props.imgNodes.length) {
        sliderRef.current?.moveToIdx(slideIndex + 1);
      } else {
        prexoNextSlide();
      }
    },
    // Keypress listener only activated if in active prexo slide
    props.parentSlideIndex === undefined || props.parentSlideIndex !== prexoSlideIndex,
  );

  useKeyPress(
    'h',
    () => {
      // h: Move to prev image.
      if (slideIndex - 1 >= 0) {
        sliderRef.current?.moveToIdx(slideIndex - 1);
      }
    },
    // Keypress listener only activated if in active prexo slide
    props.parentSlideIndex === undefined || props.parentSlideIndex !== prexoSlideIndex,
  );

  return (
    <div
      className={clsx(
        'tw-relative tw-flex tw-flex-col tw-items-center tw-gap-y-3',
        // SEP 159: Needed along with min-h-0 for <img> to properly
        // resize vertically.
        'tw-min-h-0',
        'tw-group/img-slider',
      )}
    >
      {slideIndex !== 0 ? (
        <ArrowFrame
          className={clsx(
            'tw-absolute tw-left-2 tw-top-[calc(50%-5rem)] tw-z-10',
            'tw-invisible group-hover/img-slider:tw-visible',
          )}
          onClick={() => {
            sliderRef.current?.prev();
          }}
        >
          <AngleLeftIcon size="3rem" />
        </ArrowFrame>
      ) : null}
      {slideIndex < props.imgNodes.length - 1 ? (
        <ArrowFrame
          className={clsx(
            'tw-absolute tw-right-2 tw-top-[calc(50%-5rem)] tw-z-10',
            'tw-invisible group-hover/img-slider:tw-visible',
          )}
          onClick={() => {
            sliderRef.current?.next();
          }}
        >
          <AngleRightIcon size="3rem" />
        </ArrowFrame>
      ) : null}
      {props.imgNodes.length > 1 ? (
        <div
          role="button"
          className={clsx(
            'tw-text-light tw-opacity-70',
            'tw-px-2 tw-rounded-full tw-bg-slate-800',
            'tw-absolute tw-right-2 tw-top-2 tw-z-10',
            'tw-invisible group-hover/img-slider:tw-visible',
          )}
        >
          <span className="tw-text-sm tw-font-semibold">
            {slideIndex + 1} / {props.imgNodes.length}
          </span>
        </div>
      ) : null}
      <div ref={sliderDivRef} className="keen-slider">
        {props.imgNodes.map((imgNode, index) => {
          if (imgNode.type !== 'element' || imgNode.tagName !== 'img') {
            return null;
          }
          const src = imgNode.properties?.src as string | undefined;
          if (!src) {
            return null;
          }
          const title = imgNode.properties?.title as string | undefined;
          const imageInfo = imageInfoMap.get(src);
          return (
            <div
              key={index}
              className="keen-slider__slide tw-flex tw-flex-col tw-group/img"
              onClick={e => {
                e.stopPropagation();
                if (slideIndex < props.imgNodes.length - 1) {
                  sliderRef.current?.next();
                } else {
                  prexoNextSlide();
                }
              }}
            >
              <ImageWithViewer
                src={src}
                imageInfo={imageInfo}
                // SEP 159: tw-min-h-0 needed for <img> to resize vertically.
                className="tw-mx-auto tw-max-h-[90vh] tw-object-contain tw-w-full tw-min-h-0 tw-group"
              />
              {title ? (
                <div className="tw-py-2 tw-bg-highlight tw-text-center">
                  <div className={props.gutterClassName ?? 'tw-px-3'}>{title}</div>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      {props.imgNodes.length > 1 ? <Dots curIndex={slideIndex} count={props.imgNodes.length} /> : null}
    </div>
  );
};

export default ImageSlider;

const ArrowFrame = (props: { onClick: () => void; children: React.ReactNode; className?: string }) => (
  <div
    role="button"
    className={clsx(
      'tw-text-light tw-opacity-50 hover:tw-opacity-100',
      'tw-p-2 tw-rounded-full tw-bg-slate-800',
      props.className,
    )}
    onClick={e => {
      e.stopPropagation();
      props.onClick();
    }}
  >
    {props.children}
  </div>
);

const Dots = (props: { curIndex: number; count: number }) => (
  <div className="tw-flex tw-gap-x-2">
    {[...Array(props.count)].map((_, index) => (
      <Dot key={index} selected={props.curIndex === index} />
    ))}
  </div>
);

const Dot = (props: { selected: boolean }) => (
  <div
    className={clsx(
      'tw-w-[8px] tw-h-[8px] tw-rounded-full tw-border-2',
      props.selected
        ? 'tw-border-perpul-light dark:tw-border-perpul-dark tw-bg-perpul-light dark:tw-bg-perpul-dark'
        : 'tw-border-layout-line-dark dark:tw-border-layout-line-light',
    )}
  />
);
