import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import 'keen-slider/keen-slider.min.css';
import { KeenSliderPlugin, useKeenSlider } from 'keen-slider/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { batch, useDispatch, useSelector } from 'react-redux';

import * as Config from '../config';
import { MainActionCreators } from '../state/reducer';
import * as store from '../state/store';
import { isIosWebKit } from '../util';

import useFeedEntryOps from './hooks/useFeedEntryOps';
import useNav from './hooks/useNav';

import { ContentControlBoundary } from './ContentControlContext';
import EgoMarkdown from './EgoMarkdown';
import FeedEntryActions from './FeedEntryActions';
import { DateLine } from './FeedEntryMatter';
import useApiClient from './hooks/useApiClient';
import useUserMeInternal from './hooks/useUserMeInternal';
import ImageIcon from './icon/ImageIcon';
import ImagesIcon from './icon/ImagesIcon';
import TwitterIcon from './icon/TwitterIcon';
import VideoIcon from './icon/VideoIcon';
import { useKeyPress } from './KeyPressContext';
import Alert from './lib/Alert';
import Button from './lib/Button';
import Modal, { modalGutterWideMarginClassName } from './lib/Modal';
import Spinner from './lib/Spinner';
import { useModalListener } from './ModalManagerContext';
import PrexoSlideContext from './PrexoSlideContext';
import { LiveProgressBar, LiveProgressBarMethods } from './ProgressBar';
import SmartFeedLink from './SmartFeedLink';
import ThreadQnA from './ThreadQnA';

interface IFeedEntryCpcViewerProps {
  feed: api.feed.IFeedInfo;
  entry: api.feed.IFeedEntryReference;
  closeModal: () => void;
}

// HACK: Based on modal's 3rem margin from the top of the page.
// This is fragile and it would be an improvement to automatically infer the
// offset, however, it requires a different div ref from the modal.
const MODAL_TOP_MARGIN = 48;

const FeedEntryCpcViewer = (props: IFeedEntryCpcViewerProps) => {
  const dispatch = useDispatch();
  const apiClient = useApiClient();

  const { result: contentRes } = cfe.ApiHook.useApiRead(
    apiClient,
    apiClient.feedEntryContentGet,
    { entry_id: props.entry.entry_id },
    res => res,
    !props.entry.has_cpc,
    {
      onResult: res => {
        batch(() =>
          // Preload entries to optimistically reduce queries in FeedEntryItemEmbed components.
          res.entries.map(entryGetRes => dispatch(MainActionCreators.apiCacheOverrideFeedEntryStandalone(entryGetRes))),
        );
      },
    },
  );

  // Special handling is required for iOS mobile browsers with small viewports
  // since the small viewport coupled with the lack of autoplay-videos makes
  // the experience too subpar.
  const [forceFlat, setForceFlat] = React.useState<'no' | 'yes-user-selected' | 'yes-ios-mobile'>(
    isIosWebKit() && window.matchMedia(Config.mediaBreakpoints.smMax).matches ? 'yes-ios-mobile' : 'no',
  );

  if (!cfe.ApiData.hasData(contentRes)) {
    return null;
  }
  if (cfe.CpcHelpers.isCpcPrexo(contentRes.data.content) && forceFlat === 'no') {
    return (
      <FeedEntryCpcPrexoViewer
        feed={props.feed}
        entry={props.entry}
        contentRes={contentRes.data}
        closeModal={props.closeModal}
        setForceFlat={() => setForceFlat('yes-user-selected')}
      />
    );
  } else {
    return (
      <FeedEntryCpcFlatViewer
        feed={props.feed}
        entry={props.entry}
        contentRes={contentRes.data}
        closeModal={props.closeModal}
        forcedFlatIos={forceFlat === 'yes-ios-mobile'}
      />
    );
  }
};

/**
 * There are three distinct Wheel behaviors that were tested and needed care
 * to be handled in a unified way:
 *
 * 1. Mac Touchpad: Touch gestures have lots of momentum. Long after the user
 *    has let go, the Mac is still generating wheel events at monotonically
 *    decreasing (or equal) deltas.
 * 2. Ubuntu Thinkpad Touchpad: Like the mac's touch gestures, but there is no
 *    momentum at all. Once the user has let go, the events stop. The delta
 *    values are also significantly larger (~2x).
 * 3. Desktop Mouse Wheel: Every increment of the wheel produces an identical
 *    high delta (~200). There is no momentum.
 */
const WheelControls: KeenSliderPlugin = slider => {
  let activeTimeout: ReturnType<typeof setTimeout>;
  let touchTimeout: ReturnType<typeof setTimeout>;
  let position: {
    x: number;
    y: number;
  };
  let wheelActive: boolean;

  const deltaYAbsHistory: number[] = [];
  let slowingDownCount = 0;

  /**
   * update() keeps track of the history of wheel event deltas even if our
   * heuristic has decided that the user is no longer actively scrolling
   * (momentum on mac is generating events).
   */
  function update(e: WheelEvent) {
    const deltaYAbs = Math.abs(e.deltaY);
    if (deltaYAbs <= 2) {
      // No need for this level of sensitivity (only on mac).
      return;
    }
    const deltaYAbsAvg = deltaYAbsHistory.reduce((a, b) => a + b, 0) / deltaYAbsHistory.length;
    if (deltaYAbsAvg + 1 > deltaYAbs) {
      slowingDownCount += 1;
    } else {
      slowingDownCount = 0;
    }
    deltaYAbsHistory.push(deltaYAbs);
    if (deltaYAbsHistory.length > 6) {
      // Long history necessary because mac's have a long inertial period with
      // consistent non-decreasing values.
      deltaYAbsHistory.shift();
    }
  }

  function wheelStart(e: WheelEvent) {
    position = {
      x: e.pageX,
      y: e.pageY,
    };
    slider.container.dispatchEvent(
      new CustomEvent('ksDragStart', {
        detail: {
          x: position.x,
          y: position.y,
        },
      }),
    );
  }

  function wheelEnd(_e: WheelEvent) {
    slider.container.dispatchEvent(
      new CustomEvent('ksDragEnd', {
        detail: {
          x: position.x,
          y: position.y,
        },
      }),
    );
  }

  function eventWheel(e: WheelEvent) {
    e.preventDefault();
    update(e);

    const alive: boolean = slowingDownCount <= 3;
    if (alive) {
      if (!wheelActive) {
        // If wheel isn't officially active, time to activate it!
        wheelStart(e);
        wheelActive = true;
      }
      const deltaYAbs = Math.abs(e.deltaY);
      const cappedDeltaY = Math.sign(e.deltaY) * Math.min(deltaYAbs, 80);
      position.x -= e.deltaX;
      position.y -= cappedDeltaY;
      slider.container.dispatchEvent(
        new CustomEvent('ksDrag', {
          detail: {
            x: position.x,
            y: position.y,
          },
        }),
      );
      // If no further events generate an alive signal (in other words, the
      // deltas are monotonically decreasing), the timeout will mark the
      // wheel as inactive even as events continue to be generated.
      clearTimeout(activeTimeout);
      activeTimeout = setTimeout(() => {
        wheelActive = false;
        wheelEnd(e);
      }, 50);
    }
    // Once there are no more events (regardless of whether we think the wheel
    // is active or not), we can safely clear the delta history.
    clearTimeout(touchTimeout);
    touchTimeout = setTimeout(() => {
      deltaYAbsHistory.splice(0, deltaYAbsHistory.length);
    }, 100);
  }

  slider.on('created', () => {
    slider.container.addEventListener('wheel', eventWheel, {
      passive: false,
    });
  });
};

const ResizePlugin: KeenSliderPlugin = slider => {
  const observer = new ResizeObserver(() => slider.update());

  slider.on('created', () => observer.observe(slider.container));
  slider.on('destroyed', () => observer.unobserve(slider.container));
};

const FeedEntryCpcPrexoViewer = (props: {
  feed: api.feed.IFeedInfo;
  entry: api.feed.IFeedEntryReference;
  contentRes: api.feed.IEntryContentGetResult;
  closeModal: () => void;
  setForceFlat: () => void;
}) => {
  const dispatch = useDispatch();
  const accountInfo = useUserMeInternal();
  const { navToFeed } = useNav();
  const apiClient = useApiClient();

  const feedEntryOps = useFeedEntryOps(apiClient, accountInfo, props.feed, props.entry, false);

  const md = cfe.ApiHelpers.getEntryMetadata(props.entry);
  const hgcInfo = cfe.ApiHelpers.getHomegrownContentInfo(props.entry);
  const authoredBy = hgcInfo?.author ?? null;

  const title = cfe.ApiHelpers.getEntryTitleBounded(props.entry);

  const otherModalVisible = useModalListener();

  const modalRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  const slides = cfe.CpcHelpers.contentToSlides(
    props.contentRes.content,
    props.contentRes.image_specs,
    props.contentRes.video_specs,
    props.contentRes.link_specs,
  );

  const curScrollProgress = React.useRef<number | null>(null);

  const startProgress = React.useRef(props.entry.for_viewer.last_visit?.progress);
  const startProgressUsed = React.useRef(false);

  const [sliderDivRef, sliderRef] = useKeenSlider<HTMLDivElement>(
    {
      detailsChanged: slider => {
        setSlideIndex(slider.track.details.abs);
        if (!startProgressUsed.current && startProgress.current && sliderRef.current) {
          startProgressUsed.current = true;
          sliderRef.current.moveToIdx(
            Math.min(Math.floor((startProgress.current / 100) * slides.length), slides.length - 1),
          );
        }
        if (progressBarRef.current && progressBarCurRef.current) {
          if (slider.track.details.abs === slides.length - 1) {
            curScrollProgress.current = 100;
          } else {
            curScrollProgress.current = (slider.track.details.abs / slides.length) * 100;
          }
          progressBarRef.current.update((slider.track.details.abs / slides.length) * 100);
          progressBarCurRef.current.update(((slider.track.details.abs + 1) / slides.length) * 100);
        }
      },
      drag: true,
      initial: 0,
      selector: '.slider-root > .keen-slider__slide',
      slides: { perView: 1 },
      vertical: true,
    },
    [WheelControls, ResizePlugin],
  );

  const [slideIndex, setSlideIndex] = React.useState(0);

  const progressBarRef = React.useRef<LiveProgressBarMethods | null>(null);
  const progressBarCurRef = React.useRef<LiveProgressBarMethods | null>(null);

  const topMatterRef = React.useRef<HTMLDivElement | null>(null);
  // Height must be set on the container of a vertical slider before it's
  // created in order for the individual slides to be sized correctly.
  const [topMatterHeight, setTopMatterHeight] = React.useState<number | null>(null);

  React.useLayoutEffect(() => {
    if (topMatterRef.current) {
      setTopMatterHeight(topMatterRef.current.offsetHeight);
    }
  }, []);

  const recordedUrlOpen = React.useRef(false);
  useEffect(() => {
    // Delay recording to history to ignore false positives by misclicks.
    if (props.entry.has_cpc) {
      const visitedId = setTimeout(() => {
        feedEntryOps.recordToHistory();
        recordedUrlOpen.current = true;
      }, 5000);
      return () => {
        clearTimeout(visitedId);
      };
    }
  }, [props.entry.entry_id]);

  // Do a final update of progress when closing.
  useEffect(() => {
    return () => {
      if (curScrollProgress.current && recordedUrlOpen.current) {
        updateProgress(curScrollProgress.current);
      }
    };
  }, []);

  // Check whether progress has changed regularly.
  const lastRecordedProgress = React.useRef<number | null>(null);
  useEffect(() => {
    const internalProgressUpdateIntervalId = setInterval(() => {
      if (
        curScrollProgress.current &&
        (lastRecordedProgress.current === null || curScrollProgress.current !== lastRecordedProgress.current)
      ) {
        updateProgress(curScrollProgress.current);
        lastRecordedProgress.current = curScrollProgress.current;
      }
    }, 5000);
    return () => {
      clearInterval(internalProgressUpdateIntervalId);
    };
  }, []);

  const updateProgress = (progress: number) => {
    if (!recordedUrlOpen.current) {
      return;
    }
    lastRecordedProgress.current = progress;
    dispatch(MainActionCreators.updateFeedEntryProgress(props.feed.feed_id, props.entry.entry_id, progress));
    if (accountInfo) {
      apiClient.feedEntryMarkProgress({ entry_id: props.entry.entry_id, progress });
    }
  };

  useKeyPress(
    'n',
    () => {
      // n: Next slide
      sliderRef.current?.next();
    },
    undefined,
  );

  useKeyPress(
    'p',
    () => {
      // p: Prev slide
      sliderRef.current?.prev();
    },
    undefined,
  );

  // For mobile-web, there's less visible space because there's a tendency for
  // the URL bar and bottom status bar to show. The Modal component sets a
  // smaller height for mobile-web, and this resize callback follows this same
  // height adjustment.
  React.useEffect(() => {
    const handleResize = () => {
      const sliderHeight = window.matchMedia(Config.mediaBreakpoints.smMax).matches ? `86vh` : `90vh`;
      if (topMatterHeight) {
        const heightStyle = `calc(${sliderHeight} - ${topMatterHeight}px)`;
        if (sliderRef.current) {
          sliderRef.current.container.style.height = heightStyle;
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [topMatterHeight]);

  const nextSlide = React.useCallback(() => {
    if (sliderRef.current) {
      if (slideIndex === slides.length - 1) {
        props.closeModal();
      } else {
        sliderRef.current.next();
      }
    }
  }, [props.closeModal, slideIndex]);

  return (
    <Modal.Container
      ref={modalRef}
      show={otherModalVisible === null}
      close={props.closeModal}
      // Order below other modals managed by the ModalContextManager.
      backdropClassName="!tw-z-[49]"
      closeBtnClassName="!tw-z-[49]"
      almostBlackBackdrop
      enableScreenHeight
      containerClassName="!tw-border-t-0"
      mobileHeaderRightComponent={
        <Button
          sm
          variant="secondary"
          // Override height so as to not vertically expand the mobile header.
          className="!tw-min-h-6"
          onClick={e => {
            e.stopPropagation();
            props.setForceFlat();
          }}
        >
          Flatten
        </Button>
      }
      sidebar={
        <div
          className={clsx(
            'tw-bg-highlight tw-py-3 tw-px-5',
            'tw-border-solid tw-border tw-border-x-0 tw-border-t-0 tw-border-layout-line-light dark:tw-border-layout-line-dark',
            'tw-hidden lg:tw-flex tw-rounded-none tw-rounded-r-md tw-text-primary',
          )}
        >
          <div className="tw-flex tw-flex-col tw-gap-y-2">
            <Modal.Heading4 className="!tw-mt-2 !tw-mb-0">{title}</Modal.Heading4>
            {md['.tag'] === 'ready' && md.published_at ? (
              <span className="tw-text-sm">
                <DateLine ts={md.published_at} />
              </span>
            ) : null}
            {authoredBy ? (
              <SmartFeedLink
                goToFeed={navToFeed}
                apiClient={apiClient}
                feedRef={`u/${authoredBy.user_id}`}
                displayHref={`/${authoredBy.username}`}
                className="tw-text-primary tw-text-sm"
              >
                <span className="tw-font-bold">{authoredBy.name}</span>{' '}
                <span className="tw-text-muted">@{authoredBy.username}</span>
              </SmartFeedLink>
            ) : null}
            <div className="tw-my-2">
              <FeedEntryActions
                feed={props.feed}
                entry={props.entry}
                actions={feedEntryOps}
                hideArchive
                agentMode={false}
                vertical
              />
            </div>
            <span
              role="button"
              className="tw-self-center hover:tw-text-inherit tw-text-muted tw-text-sm"
              onClick={props.setForceFlat}
            >
              Flatten
            </span>
          </div>
        </div>
      }
      sidebarBottom={
        <div
          role="button"
          className={clsx(
            'tw-bg-perpul-dark hover:tw-bg-perpul-light tw-py-2 sm:tw-py-3 tw-px-5',
            'tw-gap-y-1 sm:tw-gap-y-2',
            'tw-border-solid tw-border tw-border-x-0 tw-border-t-0 tw-border-layout-line-dark dark:tw-border-layout-line-dark',
            'tw-flex tw-flex-col sm:tw-hidden lg:tw-flex',
            'tw-rounded-none tw-rounded-b-md sm:tw-rounded-bl-none sm:tw-rounded-r-md',
            'tw-text-primary',
            'tw-w-32',
            'tw-text-white',
            'sm:tw-pulse-left',
          )}
          onClick={nextSlide}
        >
          {slideIndex === slides.length - 1 ? (
            <span className="tw-text-lg tw-leading-none tw-font-semibold">Done</span>
          ) : (
            <div className="tw-flex tw-items-center tw-gap-x-4">
              <span className="tw-text-lg tw-leading-none tw-font-semibold">Next</span>
              <DownArrow sm />
            </div>
          )}
          <span className="tw-text-sm tw-leading-none">tap / swipe</span>
        </div>
      }
    >
      {title ? (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      ) : null}
      <div ref={topMatterRef} className="tw-relative">
        <div className="tw-z-[1020]">
          <div className="tw-absolute tw-left-0 tw-top-0 tw-w-full tw-flex tw-flex-col">
            <div className="tw-h-[6px] tw-flex tw-justify-evenly tw-bg-gray-200 dark:tw-bg-gray-800">
              {[...Array(slides.length - 1)].map((_, index) => (
                <div key={index} className="tw-h-full tw-w-[4px] tw-bg-primary tw-z-10" />
              ))}
            </div>
            <div className="tw-flex">
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className="tw-flex tw-flex-1 tw-justify-center"
                  role="button"
                  onClick={() => {
                    if (sliderRef.current) {
                      sliderRef.current.moveToIdx(index);
                    }
                  }}
                >
                  {slide.media_type ? (
                    <div className="tw-px-4 tw-bg-gray-200 dark:tw-bg-gray-800 tw-bg-opacity-50 tw-rounded-b-lg tw-z-10">
                      {slide.media_type === 'video' ? (
                        <VideoIcon size="0.9rem" />
                      ) : slide.media_type === 'tweet' ? (
                        <TwitterIcon size="0.8rem" />
                      ) : slide.media_type === 'image' ? (
                        <ImageIcon size="0.8rem" />
                      ) : slide.media_type === 'images' ? (
                        <ImagesIcon size="0.9rem" />
                      ) : null}
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
          <div className="tw-absolute tw-left-0 tw-top-0 tw-w-full">
            <LiveProgressBar ref={progressBarCurRef} slowTransition />
          </div>
          {/* NOTE: This progress bar is redundant, but is kept until the
          experiment to use red for the current slide has concluded. */}
          <LiveProgressBar ref={progressBarRef} slowTransition />
        </div>
      </div>
      <Modal.Body zeroPy>
        <PrexoSlideContext.Provider value={{ nextSlide, slideIndex }}>
          {topMatterHeight ? (
            <div ref={sliderDivRef} className="keen-slider slider-root" role="button" onClick={nextSlide}>
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className="keen-slider__slide tw-pt-7 tw-pb-10 sm:tw-pb-6 tw-flex tw-flex-col tw-justify-center"
                >
                  <ContentControlBoundary>
                    <EgoMarkdown
                      ref={contentRef}
                      variant="cpc"
                      content={slide.content}
                      contentImageSpecs={slide.contentImageSpecs}
                      contentVideoSpecs={slide.contentVideoSpecs}
                      contentLinkSpecs={slide.contentLinkSpecs}
                      goToFeed={navToFeed}
                      parentSlideIndex={index}
                      gutterClassName={modalGutterWideMarginClassName}
                      isSlide
                    />
                  </ContentControlBoundary>
                  {index !== slides.length - 1 ? (
                    <div className="tw-absolute tw-w-full tw-bottom-0 tw-flex tw-justify-around tw-pb-3">
                      <DownArrow muted />
                      <DownArrow muted />
                      <DownArrow muted />
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          ) : null}
        </PrexoSlideContext.Provider>
      </Modal.Body>
    </Modal.Container>
  );
};

const DownArrow = (props: { sm?: boolean; muted?: boolean }) => (
  <div
    className={clsx(
      'tw-w-0 tw-h-0',
      props.sm ? 'tw-border-[8px]' : 'tw-border-[10px]',
      'tw-border-b-0 tw-rounded-sm tw-border-x-transparent tw-border-x-transparent',
      props.muted ? 'tw-border-t-zinc-300 dark:tw-border-t-zinc-700' : 'tw-border-t-zinc-100 dark:tw-border-t-zinc-300',
    )}
  ></div>
);

const FeedEntryCpcFlatViewer = (props: {
  feed: api.feed.IFeedInfo;
  entry: api.feed.IFeedEntryReference;
  contentRes: api.feed.IEntryContentGetResult;
  closeModal: () => void;
  forcedFlatIos: boolean;
}) => {
  const dispatch = useDispatch();
  const accountInfo = useUserMeInternal();
  const { navToFeed } = useNav();
  const apiClient = useApiClient();

  const feedEntryOps = useFeedEntryOps(apiClient, accountInfo, props.feed, props.entry, false);

  const startProgress = React.useRef(props.entry.for_viewer.last_visit?.progress);
  const startProgressUsed = React.useRef(false);

  const md = cfe.ApiHelpers.getEntryMetadata(props.entry);
  const hgcInfo = cfe.ApiHelpers.getHomegrownContentInfo(props.entry);
  const authoredBy = hgcInfo?.author ?? null;

  const { result: stimGetRes } = cfe.ApiHook.useApiReadCache(
    apiClient,
    apiClient.stimulusGet,
    { url: props.entry.url },
    res => res,
    value => dispatch(MainActionCreators.apiCacheSetStimGet(props.entry.url, value)),
    () =>
      useSelector<store.IAppState, cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.stimulus.IGetResult>>>(
        state => state.apiCache.stimGet.get(props.entry.url) ?? cfe.ApiHook.getCacheEmptySingleton(),
      ),
    props.entry['.tag'] === 'notif',
    {
      onResult: res =>
        res.topics.filter(topic => topic.feed).map(topic => dispatch(MainActionCreators.updateFeed(topic.feed!))),
    },
    120,
  );

  const { result: contentRes } = cfe.ApiHook.useApiRead(
    apiClient,
    apiClient.feedEntryContentGet,
    { entry_id: props.entry.entry_id },
    res => res,
    !props.entry.has_cpc,
    {
      onResult: res => {
        batch(() =>
          // Preload entries to optimistically reduce queries in FeedEntryItemEmbed components.
          res.entries.map(entryGetRes => dispatch(MainActionCreators.apiCacheOverrideFeedEntryStandalone(entryGetRes))),
        );
      },
    },
  );

  const recordedUrlOpen = React.useRef(false);
  useEffect(() => {
    // Delay recording to history to ignore false positives by misclicks.
    if (props.entry.has_cpc) {
      const visitedId = setTimeout(() => {
        feedEntryOps.recordToHistory();
        recordedUrlOpen.current = true;
      }, 5000);
      return () => {
        clearTimeout(visitedId);
      };
    }
  }, [props.entry.entry_id]);

  // Do a final update of progress when closing.
  useEffect(() => {
    return () => {
      if (curScrollProgress.current && recordedUrlOpen.current) {
        updateProgress(curScrollProgress.current);
      }
    };
  }, []);

  // Check whether progress has changed regularly.
  const lastRecordedProgress = React.useRef<number | null>(null);
  useEffect(() => {
    const internalProgressUpdateIntervalId = setInterval(() => {
      if (
        curScrollProgress.current &&
        (lastRecordedProgress.current === null || curScrollProgress.current !== lastRecordedProgress.current)
      ) {
        updateProgress(curScrollProgress.current);
        lastRecordedProgress.current = curScrollProgress.current;
      }
    }, 5000);
    return () => {
      clearInterval(internalProgressUpdateIntervalId);
    };
  }, []);

  const updateProgress = (progress: number) => {
    if (!recordedUrlOpen.current) {
      return;
    }
    lastRecordedProgress.current = progress;
    dispatch(MainActionCreators.updateFeedEntryProgress(props.feed.feed_id, props.entry.entry_id, progress));
    if (accountInfo) {
      apiClient.feedEntryMarkProgress({ entry_id: props.entry.entry_id, progress });
    }
  };

  useKeyPress('o', () => {
    // o: Open link in new tab
    feedEntryOps.open();
  });

  const title = cfe.ApiHelpers.getEntryTitleBounded(props.entry);
  const primaryUrl = props.entry.strong_ref?.url ?? props.entry.url;

  const curScrollProgress = React.useRef<number | null>(null);
  const curScrollProgressUpdateId = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  const otherModalVisible = useModalListener();

  const modalRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      modalRef.current &&
      contentRef.current &&
      startProgress.current &&
      cfe.ApiData.hasData(contentRes) &&
      !startProgressUsed.current
    ) {
      if (!modalRef.current) {
        return;
      }
      startProgressUsed.current = true;
      const yPos =
        (startProgress.current / 100) * (contentRef.current.scrollHeight - window.innerHeight) +
        contentRef.current.offsetTop +
        MODAL_TOP_MARGIN;
      modalRef.current.scrollTo({ top: yPos, behavior: 'smooth' });
    }
  }, [modalRef.current, contentRef.current, cfe.ApiData.hasData(contentRes)]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    const trackScroll = (e: React.UIEvent<HTMLDivElement>) => {
      if (!contentRef.current) {
        return;
      }
      if (curScrollProgressUpdateId.current) {
        clearTimeout(curScrollProgressUpdateId.current);
        curScrollProgressUpdateId.current = null;
      }
      const position = e.currentTarget.scrollTop - (contentRef.current.offsetTop + MODAL_TOP_MARGIN);
      // For content shorter than screen's worth, do not show the progress bar
      // at all as it's distracting.
      const progress =
        contentRef.current.scrollHeight - window.innerHeight > 0
          ? Math.max(
              0,
              Math.min(100, 100 * (position / Math.abs(contentRef.current.scrollHeight - window.innerHeight))),
            )
          : 0;
      if (progressBarRef.current) {
        progressBarRef.current.update(progress);
      }
      curScrollProgressUpdateId.current = setTimeout(() => {
        curScrollProgressUpdateId.current = null;
        curScrollProgress.current = progress;
      }, 3000);
    };
    // @ts-ignore
    modalRef.current.addEventListener('scroll', trackScroll);
    return () => {
      if (modalRef.current) {
        // @ts-ignore
        modalRef.current.removeEventListener('scroll', trackScroll);
      }
    };
  }, [modalRef.current, contentRef.current]);

  const progressBarRef = React.useRef<LiveProgressBarMethods | null>(null);

  return (
    <Modal.Container
      ref={modalRef}
      show={otherModalVisible === null}
      close={props.closeModal}
      // Order below other modals managed by the ModalContextManager.
      backdropClassName="!tw-z-[49]"
      closeBtnClassName="!tw-z-[49]"
      almostBlackBackdrop
      enableLgMediaSize
    >
      {title ? (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      ) : null}
      <div style={{ position: 'sticky', top: 0, zIndex: 1020 }}>
        <LiveProgressBar ref={progressBarRef} />
      </div>
      <Modal.Body>
        {contentRes.kind === 'loading' ? (
          <Modal.GutterWide>
            <div className="tw-flex tw-justify-center tw-my-6">
              <Spinner />
            </div>
          </Modal.GutterWide>
        ) : contentRes.kind === 'loaded' && contentRes.data.content_type['.tag'] === 'markdown' ? (
          <>
            <Modal.GutterWide>
              {cfe.ApiHelpers.isUrlOpenable(primaryUrl) ? (
                <div className="tw-flex tw-flex-row-reverse">
                  <a href={primaryUrl} target="_blank">
                    From {cfe.ApiHelpers.getHostnameForDisplay(primaryUrl)}
                  </a>
                </div>
              ) : null}
              {props.forcedFlatIos ? (
                <Alert variant="info">
                  <Alert.Heading>Prexo Flattened</Alert.Heading>
                  Due to limitations with mobile iOS browsers, the slides have been converted to a document format.
                  Switch to our mobile app for the full experience.
                </Alert>
              ) : null}
              <Modal.Heading1 className="!tw-my-3">{title}</Modal.Heading1>
              {authoredBy ? (
                <div className="tw-mb-2">
                  <SmartFeedLink
                    goToFeed={navToFeed}
                    apiClient={apiClient}
                    feedRef={`u/${authoredBy.user_id}`}
                    displayHref={`/${authoredBy.username}`}
                    className="tw-text-primary"
                  >
                    <span className="tw-font-bold">{authoredBy.name}</span>{' '}
                    <span className="tw-text-muted">@{authoredBy.username}</span>
                  </SmartFeedLink>
                  {md['.tag'] === 'ready' && md.published_at ? (
                    <span className="tw-text-muted">
                      {' '}
                      &bull; <DateLine ts={md.published_at} />
                    </span>
                  ) : null}
                </div>
              ) : null}
              <div className="tw-max-w-sm">
                <FeedEntryActions
                  feed={props.feed}
                  entry={props.entry}
                  actions={feedEntryOps}
                  hideArchive
                  agentMode={false}
                />
              </div>
            </Modal.GutterWide>
            <div className="tw-pt-4" />
            <EgoMarkdown
              ref={contentRef}
              variant="cpc"
              content={contentRes.data.content}
              contentImageSpecs={contentRes.data.image_specs}
              contentVideoSpecs={contentRes.data.video_specs}
              contentLinkSpecs={contentRes.data.link_specs}
              goToFeed={navToFeed}
              gutterClassName={modalGutterWideMarginClassName}
              addContentControlBoundaries
            />
          </>
        ) : contentRes.kind === 'error' ? (
          <Modal.GutterWide>
            <div>
              <p>Unexpected error.</p>
            </div>
          </Modal.GutterWide>
        ) : null}
        <div className="tw-pt-4" />
        {cfe.ApiData.hasData(stimGetRes) && stimGetRes.data.qna_thread_id ? (
          <>
            <div className="tw-pt-8" />
            <ThreadQnA
              accountInfo={accountInfo}
              apiClient={apiClient}
              navToFeed={navToFeed}
              size={{
                depth: 4,
                initDepth: 2,
                initRepliesPerLevel: 3,
                repliesPerLevel: 10,
              }}
              threadId={stimGetRes.data.qna_thread_id}
              viaEntry={props.entry}
              authorUserId={authoredBy?.user_id}
              className="kb-pad-4-na"
            />
            <div className="tw-mt-4" />
          </>
        ) : null}
      </Modal.Body>
    </Modal.Container>
  );
};

export default FeedEntryCpcViewer;
